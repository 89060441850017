<template>
	<div style="padding: 0 50px;">
		<AspectRatio width="100%" :ratio="0.4">
			<div class="banner" style="height: 100%">
				<el-carousel trigger="click" style="height: 100%">
					<el-carousel-item>
						<img src="../assets/images/Index/banner.png" style="display: block; width: 100%; height: 100%;">
					</el-carousel-item>
					<el-carousel-item>
						<a target="_blank" href="https://qkl.ylxue.net/">
							<img src="https://ylxue-bucket.oss-cn-beijing.aliyuncs.com/Carousel/qklbanner.png"
							     style="display: block; width: 100%; height: 100%;">
						</a>
					</el-carousel-item>
				</el-carousel>
				<div class="banner_notice">
					<img src="../assets/images/Index/notice.png">
					<marquee width="950" scrollamount="5" behavior="" direction="">
						<router-link v-for="item in data.noticeItems" :title="item.title" :key="item" :to="item.link">{{ item.title
							}}
						</router-link>
					</marquee>
					<router-link to="/platformAnnouncement">更多公告 ></router-link>
				</div>
			</div>
		</AspectRatio>
		
		<p class="index_classify mt20">
			<img src="../assets/images/Index/classify.png">
			<span>精品课程推荐</span>
		</p>
		<div class="course_container">
			<div class="course_title">
				<div class="course_type">
					<span @click="changeType(item.id)" v-for="(item, index) in data.classifyItems" :class="{ act:index == 0 }"
					      :key="item">{{ item.title }}</span>
				</div>
				<div class="course_change">
                    <span @click="changeCourse()">
                        换一换
                        <img src="../assets/images/Index/change.png">
                    </span>
					<router-link to="/ResourceCenter" v-if="data.logged">更多>></router-link>
				</div>
			</div>
			<div class="course_box mt20 flex">
				<AspectRatio width="25%" :ratio="1.5">
					<img src="../assets/images/Index/recommend.png" class="left-img">
				</AspectRatio>
				<div class="ml20 w100 h100">
					<div class="course-list flex flex-wrap mb30">
						<template v-for="(item,index) in data.courseItems" :key="item">
							<AspectRatio class="course-item" ratio="0.97" v-if="index<5">
								<router-link class="course-content" :to="item.href" :title="item.title" v-if="store.state.logged">
									<AspectRatio width="100%" ratio="0.55">
										<img class="img" :src="item.imgSrc">
									</AspectRatio>
									<div class="title p5">{{ item.title }}</div>
								</router-link>
                <div class="course-content" v-else @click="showLogin">
                  <AspectRatio width="100%" ratio="0.55">
                    <img class="img" :src="item.imgSrc">
                  </AspectRatio>
                  <div class="title p5">{{ item.title }}</div>
                </div>
							</AspectRatio>
						</template>
					</div>
					<div class="course-list flex flex-wrap">
						<template v-for="(item,index) in data.courseItems" :key="item">
							<AspectRatio class="course-item" ratio="0.97" v-if="index<10 && index>=5">
								<router-link class="course-content" :to="item.href" :title="item.title" v-if="store.state.logged">
									<AspectRatio width="100%" ratio="0.55">
										<img class="img" :src="item.imgSrc">
									</AspectRatio>
									<div class="title p5">{{ item.title }}</div>
								</router-link>
                <div class="course-content" v-else @click="showLogin">
                  <AspectRatio width="100%" ratio="0.55">
                    <img class="img" :src="item.imgSrc">
                  </AspectRatio>
                  <div class="title p5">{{ item.title }}</div>
                </div>
							</AspectRatio>
						</template>
					</div>
				</div>
			</div>
      <Login ref="loginRef"></Login>
		</div>
		
		<p class="questions_title" style="display: none;">题库导航</p>
		<div class="questions_container" style="display: none;">
			<a v-for="item in data.questionsItems" :key="item" :href="item.link">{{ item.name }}({{ item.num }})</a>
		</div>
		
		<p class="index_classify mt50">
			<img style="width: 49px;" src="../assets/images/Index/superiority.png">
			<span>我们的优势</span>
		</p>
		<ul class="superiority_container">
			<li v-for="(item, index) in data.superiorityItems" :key="index">
				<img :src="getSuperiorityImgUrl(index)">
				<h3>{{ item.title }}</h3>
				<p>{{ item.describe }}</p>
			</li>
		</ul>
		
		<p class="index_classify">
			<img src="../assets/images/Index/expert.png">
			<span>专家团队</span>
		</p>
		<div class="index_swiper">
			<swiper
					:slides-per-view="swiper_options.slides_per_view"
					:space-between="swiper_options.space_between"
					:navigation="swiper_options.navigation"
					:autoplay="swiper_options.autoplay"
					:loop="swiper_options.loop"
			>
				<swiper-slide>
					<div>
						<img class="swiper-img" src="../assets/images/Index/expert1.png">
						<div>
							<h1>向东</h1>
							<p>北京清华大学机械工程系副研究员</p>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div>
						<img class="swiper-img" src="../assets/images/Index/expert2.png">
						<div>
							<h1>沈碧津</h1>
							<p>清华北大特聘讲师</p>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div>
						<img class="swiper-img" src="../assets/images/Index/expert3.png">
						<div>
							<h1>朱涛</h1>
							<p>建筑师、建筑评论家、香港大学建筑系助理教授</p>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div>
						<img class="swiper-img" src="../assets/images/Index/expert4.png">
						<div>
							<h1>苏英</h1>
							<p>北京大学医学部讲师</p>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div>
						<img class="swiper-img" src="../assets/images/Index/expert5.png">
						<div>
							<h1>傅思明</h1>
							<p>中央党校政法政法部宪法与行政法教研室主任</p>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div>
						<img class="swiper-img" src="../assets/images/Index/expert6.png">
						<div>
							<h1>宋承敏</h1>
							<p>著名经济学家、原国家经贸委信息中心副主任</p>
						</div>
					</div>
				</swiper-slide>
				
				<img src="../assets/images/Index/swiperArrow.png" class="swiper-button-next">
				<img src="../assets/images/Index/swiperArrow.png" class="swiper-button-prev">
			</swiper>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {useStore} from 'vuex'
import axios from "axios";
import {reactive,ref, onMounted, getCurrentInstance} from 'vue';
import SwiperCore, {Navigation, Autoplay,} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/vue'
import "swiper/scss";
import "swiper/scss/navigation";
import AspectRatio from "@/components/AspectRatio.vue";
import Login from '@/components/login.vue';
SwiperCore.use([Navigation, Autoplay]);
const loginRef = ref()
// 获取自定义接口
const publicApiUrl = getCurrentInstance().appContext.config.globalProperties.$publicPath.publicApiUrl;

// 获取apiUrl
const store = useStore();
const isShow = ref(false)
const data: any = reactive({
	logged: store.state.logged,
	noticeItems: [],
	classifyItems: [
		{
			id: '69',
			title: '护理专业',
		},
		{
			id: '84',
			title: '畜牧业',
		},
		{
			id: '36',
			title: '农业',
		},
		{
			id: '45',
			title: '水利水电',
		},
		{
			id: '53',
			title: '人力资源管理',
		},
		{
			id: '23',
			title: '市政城建工程',
		},
	],
	courseItems: [],
	questionsItems: [
		{
			name: '环境保护工程',
			num: '89',
			link: '/sss',
		},
		{
			name: '法律',
			num: '222',
			link: '/ddd',
		},
		{
			name: '美术',
			num: '333',
			link: '/fff',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/ggg',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/sss',
		},
		{
			name: '法律',
			num: '222',
			link: '/ddd',
		},
		{
			name: '美术',
			num: '333',
			link: '/fff',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/ggg',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/sss',
		},
		{
			name: '法律',
			num: '222',
			link: '/ddd',
		},
		{
			name: '美术',
			num: '333',
			link: '/fff',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/ggg',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/sss',
		},
		{
			name: '法律',
			num: '222',
			link: '/ddd',
		},
		{
			name: '美术',
			num: '333',
			link: '/fff',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/ggg',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/sss',
		},
		{
			name: '法律',
			num: '222',
			link: '/ddd',
		},
		{
			name: '美术',
			num: '333',
			link: '/fff',
		},
		{
			name: '环境保护工程',
			num: '89',
			link: '/ggg',
		},
	],
	superiorityItems: [
		{
			title: '原创课程多',
			describe: '目前原创课程达到3650课时',
		},
		{
			title: '师资力量雄厚',
			describe: '合作名校名师1200多名',
		},
		{
			title: '合作客户多',
			describe: '全国合作客户已达2018个',
		},
		{
			title: '培训人数多',
			describe: '全国培训人数达到6285932人',
		},
		{
			title: '涉及专业多',
			describe: '涉及全国43个专业',
		},
	],
	courseId: '69',
});

// 轮播图配置
const swiper_options: any = reactive({
	slides_per_view: 4,
	space_between: 15,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false
	},
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next', //前进后退按钮
		prevEl: '.swiper-button-prev',
	},
});
// 课程分类切换专业
const changeType: any = (id: any) => {
	data.courseId = id;
	$('.course_type span').removeClass('act');
	for (var i = 0; i < data.classifyItems.length; i++) {
		if (id == data.classifyItems[i].id) {
			$('.course_type span').eq(i).addClass('act');
			break;
		}
	}
	getCourseData();
};
// 课程分类本专业切换课程展示
const changeCourse: any = () => {
	getCourseData();
};
// 根据下标返回图片路径
const getSuperiorityImgUrl: any = (index: any) => {
	return require("../assets/images/Index/superiority" + (index + 1) + ".png")
};
// 获取课程分类中的课程
const getCourseData: any = () => {
	axios.post(publicApiUrl.GetRecommendResource, {
		PageIndex: 1,
		PageSize: 10,
		IsTop: 1,
		FirsttypeId: data.courseId,
	}).then(function (res) {
				if (res.status == 200) {
					data.courseItems = [];
					for (var i of res.data) {
						data.courseItems.push({
							title: i.CourseName,
							imgSrc: 'https://ylxue-bucket.oss-cn-beijing.aliyuncs.com/' + i.PcImg,
							href: '/CourseDetails?id=' + i.CourseCode,
							star: '',
							num: '',
						})
					}
					
				} else {
					console.error(res);
				}
			},
			function (res) {
				console.error(res);
			}
	);
};

onMounted(() => {
	// 获取公告数据
	axios.post(publicApiUrl.GetNoticePage, {
		current: 1,
		size: 999,
		s_webSite: store.state.s_webSite,
	}).then(function (res) {
				if (res.status == 200) {
					data.noticeItems = [];
					for (var i of res.data.data) {
						data.noticeItems.push({
							link: '/AnnouncementDetails?id=' + i.i_id,
							title: '[' + new Date(parseInt(i.s_createTime) * 1000).toLocaleDateString().slice().replace(/\//g, '-') + '] ' + i.s_title,
						})
					}
				} else {
					console.error(res);
				}
			},
			function (res) {
				console.error(res);
			}
	);
	getCourseData();
})

const onresize = () => {
	if (window.innerWidth < 1280) {
		swiper_options.slides_per_view = 4
	} else {
		swiper_options.slides_per_view = 6
	}
}

window.onresize = onresize
onresize()
const showLogin = ()=>{
  loginRef.value.entryVisible = true
}
</script>
<style scoped lang="scss">
.banner {
	position: relative;
	
	:deep(.el-carousel__container) {
		height: 100% !important;
	}
	
	.banner_shadow {
		z-index: 1;
		background: black;
		opacity: .4;
		line-height: 48px;
	}
	
	.banner_notice {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		height: 48px;
		background: rgba(0, 0, 0, 0.35);
		align-items: center;
		padding: 0 20px;
		box-sizing: border-box;
		
		&:nth-child(1) {
			flex-shrink: 0;
			width: auto;
			height: 80%;
		}
		
		> marquee {
			flex: 1;
			margin: 0 20px;
			
			> a {
				margin: 0 30px;
			}
		}
		
		> a {
			flex-shrink: 0;
		}
	}
}

.index_classify {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	
	img {
		width: 44px;
		margin-right: 5px;
	}
	
	span {
		font-weight: bold;
		font-size: 36px;
	}
}

.course_container {
	border: 1px solid rgb(200, 188, 188);
	box-sizing: border-box;
	padding: 30px;
	
	.course_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		.course_type {
			span {
				color: #5493f8;
				font-weight: bold;
				font-size: 18px;
				margin-right: 20px;
				cursor: pointer;
			}
			
			.act {
				border-bottom: 3px solid #5493f8;
			}
		}
		
		img {
			margin-left: 5px;
			width: 18px;
		}
		
		.course_change {
			display: flex;
			
			span {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #aaa;
				margin-right: 50px;
				cursor: pointer;
				font-size: 18px;
			}
			
			a {
				color: #aaa;
				font-size: 18px;
			}
		}
	}
}

.questions_title {
	border-left: 3px solid rgb(28, 137, 255);
	padding-left: 5px;
	font-weight: bold;
	font-size: 16px;
	color: rgb(28, 137, 255);
	margin: 85px 0 15px;
}

.questions_container {
	border: 1px solid rgb(200, 188, 188);
	box-sizing: border-box;
	padding: 23px 23px 0;
	margin-bottom: 125px;
	
	a {
		color: rgb(28, 137, 255);
		font-size: 16px;
		display: inline-block;
		margin: 0 20px 23px 0;
	}
}

.superiority_container {
	display: flex;
	padding: 0 75px;
	margin: 90px 0 65px;
	
	li {
		width: calc((100% - 290px) / 4);
		margin-right: 20px;
		position: relative;
		box-sizing: border-box;
		text-align: center;
		border: 1px solid #e7f6fe;
		height: 231px;
		
		img {
			width: 110px;
			position: absolute;
			top: -25%;
			left: 50%;
			transform: translateX(-50%);
		}
		
		h3 {
			margin: 80px 0 50px;
			color: rgb(11, 12, 12);
			font-size: 24px;
		}
		
		p {
			color: rgb(170, 183, 183);
			font-size: 14px;
		}
	}
	
	li:last-child {
		margin-right: 0;
	}
}

.index_swiper {
	position: relative;
	margin-bottom: 55px;
	
	> div {
		position: initial;
		margin: 0 70px;
	}
	
	.swiper-slide {
		box-sizing: border-box;
		padding: 5px 10px;
		background-color: #f9f9f9;
		
		> div {
			border: 1px solid #c3c3c3;
			padding: 3px;
			
			img {
				height: 205px;
				display: block;
				width: 100%;
			}
			
			div {
				margin: 15px 0;
				height: 90px;
				text-align: center;
				
				h1 {
					color: #5888e9;
					font-weight: bold;
					font-size: 20px;
					margin-right: 10px;
				}
				
				p {
					margin-top: 15px;
					padding: 0 20px;
					color: #c9c9c9;
					font-size: 16px;
					line-height: 22px;
				}
			}
		}
	}
	
	.swiper-button-prev, .swiper-button-next {
		width: 20px;
		height: 28px;
	}
	
	.swiper-button-next {
		right: 45px;
		transform: rotate(180deg);
	}
	
	.swiper-button-prev {
		left: 45px;
	}
}

.course_box {
	.left-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		flex-shrink: 0;
	}
	
	.course-list {
		flex: 1;
		--spaceing-x: 20px;
		--item-cols: 5;
		
		.course-item {
			width: calc(calc(100% - calc(var(--item-cols) - 1) * var(--spaceing-x)) / var(--item-cols));
			margin-right: var(--spaceing-x);
			background: #516fe600;
			border: 1px solid #cccccc;
			box-sizing: border-box;
			
			&:nth-child(5n) {
				margin-right: 0;
			}
			
			.course-content {
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: transform 0.2s ease-in;
					
					&:hover {
						transform: scale(1.2);
					}
				}
				
				.title {
					font-size: 16px;
					font-weight: normal;
					color: #333333;
				}
			}
		}
	}
}

.swiper-img {
	object-fit: cover;
}

</style>