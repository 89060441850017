<template>
	<div class="header">
	  <div class="logoClass">
      <img src="../assets/images/header_logo.png">
      <span>教育资源共建共享服务平台</span>
    </div>
		<div class="header_container">
			<div>
        <router-link v-for="item in data.linkItems" :key="item" :to="item.link" v-show="item.show">{{ item.name }}
        </router-link>
			</div>
			<div class="header_center" v-if="store.state.logged">
				<router-link to="/basicInformation">个人中心</router-link>
				|
				<el-popconfirm
						confirm-button-text="是"
						cancel-button-text="否"
						title="确认退出?"
						@confirm="quitHandle()"
				>
					<template #reference>
						<a>退出</a>
					</template>
				</el-popconfirm>
			</div>
			<div class="header_login" v-else>
				<a @click="entryVisible = true">登录</a>
				<!-- <router-link to="/companyDetails?type=1">立即入驻</router-link> -->
			</div>
		</div>
		
		<el-dialog width="400px" v-model="entryVisible" title="登录">
			<el-form
					class="entryForm"
					ref="entryForm"
					:model="entryFormData"
					:rules="entryRules"
			>
				<el-form-item prop="Account">
					<el-input v-model="entryFormData.Account" autocomplete="off" placeholder="邮箱/手机号:"/>
				</el-form-item>
				
				<el-form-item prop="password">
					<el-input type="password" show-password v-model="entryFormData.password" placeholder="请输入密码"/>
				</el-form-item>
				
				<el-button style="width: 100%;" type="primary" @click="entrySubmit(entryFormData)">登录</el-button>
				
				<div class="form_link">
					<!-- <router-link @click="entryVisible = false;" to="/companyDetails?type=1" >立即入驻</router-link> -->
					<a @click="forgetVisible = true;">忘记密码</a>
				</div>
			</el-form>
		</el-dialog>
		
		<el-dialog width="400px" v-model="forgetVisible" title="忘记密码">
			<el-form
					ref="forgetForm"
					:model="forgetFormData"
					:rules="forgetRules"
			>
				<el-form-item prop="phone">
					<el-input v-model="forgetFormData.phone" autocomplete="off" placeholder="请输入手机号,最大为11位"/>
				</el-form-item>
				
				<el-form-item prop="password">
					<el-input type="password" show-password v-model="forgetFormData.password"
					          placeholder="请输入新密码,字母或数字,长度为8-20位"/>
				</el-form-item>
				
				<el-form-item prop="checkPassword">
					<el-input type="password" show-password v-model="forgetFormData.checkPassword"
					          placeholder="请确认新密码,字母或数字,长度为8-20位"/>
				</el-form-item>
				
				<el-form-item prop="code">
					<el-input style="width: calc(100% - 13px - 92px); margin-right: 13px;"
					          oninput="value=value.replace(/[^\d-]/g,'');" v-model="forgetFormData.code"
					          placeholder="请输入验证码,长度为4-6位"/>
					<el-button size="small" :disabled="forgetFormData.disabled" type="primary" @click="getVerificationCode()">
						获取验证码
					</el-button>
				</el-form-item>
				
				<el-button style="width: 100%;" type="primary" @click="forgetSubmit(forgetFormData)">找回密码</el-button>
			</el-form>
		</el-dialog>
		
		<el-dialog width="400px" v-model="codeVisible" title="请输入验证码">
			<p style="text-align: center; font-size: 28px; font-weight: bold; color: #009fff; margin-bottom: 22px; cursor: pointer;"
			   @click="getVerificationCode()">{{ codeFormData.verificationCode }}</p>
			<el-form
					ref="codeForm"
					:model="codeFormData"
					:rules="codeFormRules"
			>
				<el-form-item prop="code">
					<el-input v-model="codeFormData.code" autocomplete="off" placeholder="请输入验证码"/>
				</el-form-item>
				
				<el-button style="width: 100%;" type="primary" @click="getForgetCode(dialogFormRules)">发送验证</el-button>
			</el-form>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
// 导入实例
import {useStore} from 'vuex'
import axios from "axios";
import {useRouter} from "vue-router"
import {reactive, ref, getCurrentInstance, watch} from 'vue';
import type {FormInstance, FormRules} from 'element-plus'
import {ElMessage} from 'element-plus'

// 获取自定义接口
const publicApiUrl = getCurrentInstance().appContext.config.globalProperties.$publicPath.publicApiUrl;

// 获取apiUrl
const store: any = useStore();
// dialog控制
const entryVisible = ref(false);
const forgetVisible = ref(false);
const codeVisible = ref(false);
// 获取参数
const router: any = useRouter();
// 表单内容
const entryForm: any = ref<FormInstance>()
const forgetForm: any = ref<FormInstance>()
const codeForm: any = ref<FormInstance>()

const entryFormData = reactive({
	Account: '',
	password: '',
})
const forgetFormData = reactive({
	phone: '',
	password: '',
	checkPassword: '',
	code: '',
	disabled: false,
})
const codeFormData = reactive({
	verificationCode: '',
	code: '',
})
// 自定义验证
const checkAgainPassword = (rule: any, value: any, callback: any) => {
	if (value != forgetFormData.password) {
		callback(new Error());
	} else {
		callback();
	}
}
// 验证规则
const entryRules: any = reactive<FormRules>({
	Account: [
		{required: true, message: '请输入邮箱/手机号', trigger: 'blur'},
	],
	password: [
		{required: true, message: '请输入密码', trigger: 'blur'},
		{pattern: /[0-9a-zA-Z]{8,20}/, message: "密码必须为字母加数字,长度为8-20位", trigger: "blur"},
	],
})
const forgetRules: any = reactive<FormRules>({
	phone: [
		{required: true, message: '请输入手机号', trigger: 'blur'},
		{max: 11, message: '手机号长度必须最大为11位', trigger: 'blur'},
	],
	password: [
		{required: true, message: '请输入密码', trigger: 'blur'},
		{pattern: /[0-9a-zA-Z]{8,20}/, message: "密码必须为字母加数字,长度为8-20位", trigger: "blur"},
	],
	checkPassword: [
		{required: true, message: '请再次输入新登录密码', trigger: 'blur'},
		{validator: checkAgainPassword, message: '两次输入的新登录密码必须相同',}
	],
	code: [
		{required: true, message: '请输入验证码', trigger: 'blur'},
		{min: 4, max: 6, message: '验证码长度为4-6位', trigger: 'blur'},
	],
})
const codeFormRules: any = reactive<FormRules>({
	code: [
		{required: true, message: '请输入验证码', trigger: 'blur'},
	],
})

// 登录提交
const entrySubmit = async (formEl: FormInstance | undefined) => {
	try {
		await entryForm.value.validate((valid: any, fields: any) => {
			if (valid) {
				axios.post(publicApiUrl.login, {
					Account: entryFormData.Account,
					loginpwd: entryFormData.password,
				}).then(function (res) {
							if (res.status == 200) {
								if (res.data.Code) {
									ElMessage({
										message: res.data.Detail,
										type: 'error',
										duration: 1000,
									})
								} else {
									ElMessage.success('登录成功!');
									data.logged = true;
									store.state.logged = true;
									entryVisible.value = false;
									store.state.userData = res.data;
									sessionStorage.setItem('userData', JSON.stringify(res.data));
									history.go(0);
								}
							} else {
								console.error(res);
							}
						},
						function (res) {
							console.error(res);
						}
				);
			} else {
				console.log('提交失败!',)
			}
		})
	} catch (error) {
		console.log('错误B');
	}
}
// 绑定手机号获取后台验证码
const getVerificationCode = (value: any) => {
	axios.get(publicApiUrl.SendVerificationCode + '?mobile=' + forgetFormData.phone).then(function (res) {
				if (res.status == 200) {
					if (res.data.Code) {
						ElMessage.error(res.data.Detail);
					} else {
						codeFormData.verificationCode = res.data;
						codeVisible.value = true;
					}
				} else {
					console.error(res);
				}
			},
			function (res) {
				console.error(res);
			}
	);
}
// 绑定手机号获取短信验证码
const getForgetCode = (value: any) => {
	if (codeFormData.verificationCode == codeFormData.code) {
		axios.get(publicApiUrl.SendMobileVerificationCode + '?mobile=' + forgetFormData.phone).then(function (res) {
					if (res.status == 200) {
						if (res.data != 'sucess') {
							ElMessage.error(res.data.Msg);
						} else {
							ElMessage.success('短信验证码已发送!');
							codeFormData.code = '';
							codeVisible.value = false;
							forgetFormData.disabled = true;
							setTimeout(() => {
								forgetFormData.disabled = false;
							}, 1000 * 60 * 5);
						}
					} else {
						console.error(res);
					}
				},
				function (res) {
					console.error(res);
				}
		);
	} else {
		ElMessage.error('有必填项未填写或验证未通过!');
	}
	
}
// 忘记密码提交
const forgetSubmit = async (formEl: FormInstance | undefined) => {
	try {
		await forgetForm.value.validate((valid: any, fields: any) => {
			if (valid) {
				forgetFormData.disabled = false;
				axios.post(publicApiUrl.RetrievePassword, {
					Mobile: forgetFormData.phone,
					NewPassword: forgetFormData.password,
					VerificationCode: forgetFormData.code,
				}).then(function (res) {
							if (res.status == 200) {
								if (res.data.Code) {
									ElMessage({
										message: res.data.Detail,
										type: 'error',
										duration: 1000,
									})
								} else {
									ElMessage.success('密码重置成功!');
									forgetVisible.value = false;
									forgetFormData.phone = '';
									forgetFormData.password = '';
									forgetFormData.checkPassword = '';
									forgetFormData.code = '';
								}
							} else {
								console.error(res);
							}
						},
						function (res) {
							console.error(res);
						}
				);
			} else {
				console.log('提交失败!',)
			}
		})
	} catch (error) {
		console.log('错误B');
	}
}

// 退出事件
const quitHandle: any = () => {
	ElMessage.success('退出成功!');
	data.logged = false;
	store.state.logged = false;
  data.linkItems[1].show = false;
	store.state.userData = null;
	//sessionStorage.removeItem('userData');
  window.sessionStorage.clear()
	router.replace('/');
}

const data: any = reactive({
  logged: store.state.logged,
  linkItems: [
    {
      name: '首页',
      link: '/',
      show: true,
    },
    {
      name: '资源中心',
      link: '/ResourceCenter',
      show: store.state.logged,
    },
    {
      name: '文档中心',
      link: '/DocumentCenter',
      show: true,
    },
    {
      name: '平台公告',
      link: '/platformAnnouncement',
      show: true,
    },
    {
      name: '联系我们',
      link: '/contactUs',
      show: true,
    },
  ],
});
</script>
<style scoped lang="scss">
.header {
	padding: 35px 50px;
	overflow: hidden;
	min-width: 1024px;
	box-sizing: border-box;
  display: flex;
  justify-content: space-between;
	align-content: center;
	img {
		float: left;
		height: 77px;
	}
	
	.header_container {
		float: right;
		overflow: hidden;
		line-height: 81px;
		
		div {
			float: left;
			
			a {
				color: #009fff;
				font-size: 18px;
				margin: 0 20px;
				position: relative;
			}
			
			.router-link-active:before {
				position: absolute;
				z-index: 1;
				left: 0;
				bottom: -14px;
				height: 3px;
				width: 100%;
				background-color: #446ffa;
				content: "";
			}
		}
		
		.header_login {
			margin: 25px 0 0 40px;
			line-height: 30px;
			border: 1px solid rgb(0, 159, 255);
			
			a {
				font-size: 16px;
				margin: 0;
				padding: 0 20px;
				position: relative;
				cursor: pointer;
			}
			
			a:last-child:before {
				position: absolute;
				z-index: 1;
				left: -0;
				bottom: 0;
				height: 100%;
				width: 0;
				background-color: #777;
				content: "";
			}
		}
		
		.header_center {
			a {
				margin: 0 5px;
				color: rgb(17, 112, 218);
				cursor: pointer;
			}
		}
	}
}

.entryForm {
	button {
		margin-bottom: 20px;
	}
	
	.form_link {
		text-align: center;
		
		a {
			margin: 0 20px;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 1440px) {
	.header .header_container div a {
		margin: 0 5.5px;
	}
	.header .header_container .header_login {
		margin: 25px 0 0 20px;
	}
}
.logoClass{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
}
</style>